/**
 *
 * Button
 *
 */

import React from 'react';
import {
  SFullButton,
  SOutlineButton,
  SHeroZooplaMoveBtn,
  SHeroRMoveBtn,
} from './styles';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import ArrowThinRight from 'images/svg/arrow-thin-right.svg';
import ArrowThinLeft from 'images/svg/left-arrow-thin.svg';

const Button = ({
  children,
  variant,
  onClick,
  className,
  type,
  buttonID,
  $backgroundColor,
  fontSize,
  disabled,
  $withArrow = false,
  margin,
  width,
  $fontColor,
  height,
  $extraStyle,
  prevArrow = false,
  text,
}) => {
  const FinalClass = variant === 'full' ? SFullButton : SOutlineButton;
  const onClickFunc = typeof onClick === 'string' ? null : onClick;

  const FinalButton = () => (
    <FinalClass
      type={type}
      className={className}
      onClick={onClickFunc}
      id={buttonID}
      $backgroundColor={$backgroundColor}
      fontSize={fontSize}
      disabled={disabled}
      $withArrow={$withArrow}
      margin={margin}
      width={width}
      height={height}
      $fontColor={$fontColor}
      $extraStyle={$extraStyle}
    >
      {prevArrow && (
        <div className="ms-1 me-2">
          <ArrowThinLeft />
        </div>
      )}
      {children}
      {$withArrow && (
        <div className="ms-1">
          <ArrowThinRight />
        </div>
      )}
    </FinalClass>
  );

  const returnBtn = type => {
    if (type === 'zoopla') {
      return (
        <SHeroZooplaMoveBtn
          className={className}
          onClick={
            () =>
              window.open(
                'https://www.zoopla.co.uk/find-agents/letting-agents/?company_name=Oasis%20Living&radius=0&search_source=find-agents%2Fletting-agents',
                '_blank',
              )
            // navigate(
            //   'https://www.zoopla.co.uk/find-agents/letting-agents/?company_name=Oasis%20Living&radius=0&search_source=find-agents%2Fletting-agents',
            // )
          }
          width={width}
          rightImageFilename="tenant_home/zoopla.png"
        >
          {text || 'View properties on Zoopla'}
        </SHeroZooplaMoveBtn>
      );
    } else if (type === 'rightmove') {
      return (
        <SHeroRMoveBtn
          className={className}
          onClick={
            () =>
              window.open(
                'https://www.rightmove.co.uk/property-to-rent/find/Oasis-Living/London.html?locationIdentifier=BRANCH%5E220118&propertyStatus=all&includeLetAgreed=true&_includeLetAgreed=on',
                '_blank',
              )
            // navigate(
            //   'https://www.rightmove.co.uk/property-to-rent/find/Oasis-Living/London.html?locationIdentifier=BRANCH%5E220118&propertyStatus=all&includeLetAgreed=true&_includeLetAgreed=on',
            // )
          }
          width={width}
          rightImageFilename="tenant_home/right_move.png"
        >
          {text || 'View properties on Rightmove'}
        </SHeroRMoveBtn>
      );
    } else {
      return typeof onClick === 'string' ? (
        <Link to={onClick} id={buttonID}>
          <FinalButton />
        </Link>
      ) : (
        <FinalButton />
      );
    }
  };

  return returnBtn($backgroundColor);
};

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  type: PropTypes.any,
  buttonID: PropTypes.string,
  $backgroundColor: PropTypes.string,
  fontSize: PropTypes.string,
  disabled: PropTypes.bool,
  $withArrow: PropTypes.bool,
  prevArrow: PropTypes.bool,
  margin: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.func,
  $fontColor: PropTypes.string,
  $extraStyle: PropTypes.object,
  text: PropTypes.string,
};

export default Button;
