/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
// Note: You can change "images" to whatever you'd like.

const Image = ({ filename, alt, styles = null, className = null }) => (
  <StaticQuery
    query={graphql`
      {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: CONSTRAINED
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(filename);
      });
      if (!image) {
        return null;
      }

      const imageAlt = filename.split('.')[0];
      //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      return (
        <GatsbyImage
          image={image.node.childImageSharp.gatsbyImageData}
          className={className}
          style={styles}
          alt={alt ? alt : imageAlt}
        />
      );
    }}
  />
);

Image.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
  styles: PropTypes.object,
  className: PropTypes.string,
};

export default Image;
