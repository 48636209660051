/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 *
 * To add custom structured data to the page, import SEO component and specify schemaMarkup variable to a new dict with structured data values
 * Easiest way is to use https://technicalseo.com/tools/schema-markup-generator/ and copy the dict.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { OLIVE_DOMAIN } from 'utils/constants';
import ogImg from '../images/main_seo_image.jpg';
import { useLocation } from '@gatsbyjs/reach-router';

let structuredData = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Oasis Living',
  alternateName: 'Online letting agents and Property management',
  url: 'https://oasis-living.com/',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'London, UK',
    postalCode: 'E14 5EU',
    streetAddress: '30 Churchill Place, Canary Wharf',
    email: 'info@oasis-living.com',
    faxNumber: '+44 20 3936 9698',
  },
  sameAs: ['https://www.linkedin.com/company/oasisliving/'],
};

function SEO({
  description,
  lang,
  meta,
  title,
  imgSrc,
  schemaMarkup = null,
  useTitleTemplate = true,
}) {
  const location = useLocation();

  const { site, allFile } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `,
  );

  let finalSchemaMarkup = Object.assign({}, structuredData, schemaMarkup);

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  let ogImageSrc = imgSrc ? imgSrc : ogImg;

  if (!ogImageSrc.includes('https://oasis-living.com')) {
    ogImageSrc = OLIVE_DOMAIN + ogImageSrc;
  }

  if (finalSchemaMarkup.image) {
    finalSchemaMarkup.image[0] = finalSchemaMarkup.image[0] || ogImageSrc;
  } else {
    finalSchemaMarkup.image = [ogImageSrc];
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        useTitleTemplate && defaultTitle ? `%s | ${defaultTitle}` : null
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: 'Oasis Living',
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImageSrc,
        },
        {
          property: `og:url`,
          content: OLIVE_DOMAIN + location.pathname,
        },
        {
          property: `twitter:site`,
          content: OLIVE_DOMAIN + location.pathname,
        },
        {
          property: `twitter:image`,
          content: ogImageSrc,
        },
        {
          property: `twitter:card`,
          content: `summary`,
        },
        {
          property: `twitter:creator`,
          content: site.siteMetadata?.author || `Oasis Living`,
        },
        {
          property: `twitter:title`,
          content: title,
        },
        {
          name: `p:domain_verify`,
          content: '89e2c4ec318648e9106603edd367a7dc',
        },
        {
          property: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {finalSchemaMarkup && (
        <script type="application/ld+json">
          {/* Remove falsy values, which is useful in the case of the blog pages not needing the address field of the schema thereby setting it to undefined */}
          {JSON.stringify(JSON.parse(JSON.stringify(finalSchemaMarkup)))}
        </script>
      )}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
  schemaMarkup: PropTypes.any,
  useTitleTemplate: PropTypes.bool,
};

export default SEO;
