/**
 *
 * Footer
 *
 */

import EmailIcon from 'images/svg/basic-mail.svg';
import TelephoneIcon from 'images/svg/vector.svg';
import React from 'react';
import { Col, Container } from 'react-bootstrap';
import Icon from '../Icon';
import {
  SFooter,
  BoldTitle,
  SLink,
  SLinkHref,
  SCol,
  SRow,
  SOasisLiving,
  SFooterMessage,
  SFooterMessageLink,
  STelephoneNumber,
  SSocialMediaLinks,
  SContainer,
  SEmailUsButton,
} from './styles';
import PropTypes from 'prop-types';
import DynamicReactIcon from 'components/DynamicReactIcon';
import DotSVG from 'components/DotSVG';
import Logo from 'components/Logo';
import AffiliationsSection from 'containers/AffiliationsSection';

// import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const FooterIcon = ({ iconVariant }) => (
  <Icon
    iconVariant={iconVariant}
    withBackground={false}
    width={32}
    height={32}
    fill="#FFFFFF"
  />
);

const Footer = () => {
  return (
    <SFooter>
      <DotSVG top $moveY="0" />
      <SContainer>
        <SRow className="mt-5">
          <Col xs={12} />
          <SCol xs="12" sm="12" md="12" lg="6">
            <SOasisLiving>
              <Logo bgColor="white" width={250} />
            </SOasisLiving>
            <SFooterMessage>
              Forget the high street agencies take your next move online with us
              and move in faster while paying 0% fees. Join the rent revolution.
            </SFooterMessage>
            <SFooterMessageLink href="/#">
              Join the rent revolution
            </SFooterMessageLink>
            <SRow className="mt-5 justify-content-center justify-content-lg-start">
              <SCol xs="12" sm="6" md="auto" className="my-auto">
                <SEmailUsButton href="mailto:info@oasis-living.com">
                  EMAIL US
                  <EmailIcon />
                </SEmailUsButton>
              </SCol>
              <SCol xs="12" sm="6" md="auto" className="my-auto">
                <STelephoneNumber href="tel:+442039369698">
                  <TelephoneIcon style={{ marginRight: 10 }}> </TelephoneIcon>{' '}
                  +44 20 3936 9698
                </STelephoneNumber>
              </SCol>
              <SCol xs="12" sm="12" md="4" lg="12" xl="12" className="my-auto">
                <SSocialMediaLinks>
                  <a
                    href="https://www.twitter.com/oasislivinguk"
                    target="_blank"
                  >
                    <DynamicReactIcon
                      fill="#e85270"
                      width={20}
                      height={17}
                      withBackground={false}
                      iconVariant="twitter"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/oasislivinguk"
                    target="_blank"
                  >
                    <DynamicReactIcon
                      fill="#e85270"
                      width={20}
                      height={17}
                      withBackground={false}
                      iconVariant="facebook"
                    />
                  </a>
                  <a href="https://instagram.com/oasislivinguk" target="_blank">
                    <DynamicReactIcon
                      fill="#e85270"
                      width={20}
                      height={17}
                      withBackground={false}
                      iconVariant="instagram"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/oasisliving"
                    target="_blank"
                  >
                    <DynamicReactIcon
                      fill="#e85270"
                      width={20}
                      height={17}
                      withBackground={false}
                      iconVariant="linkedin"
                    />
                  </a>
                </SSocialMediaLinks>
              </SCol>
            </SRow>
          </SCol>
          <SCol xs="12" sm="4" md="4" lg="2">
            <BoldTitle>Quick Links</BoldTitle>
            <SLinkHref href="https://oasis-living.com">Home</SLinkHref>
            <SLinkHref href="https://oasis-living.com/about-us/">
              About
            </SLinkHref>
            <SLinkHref href="https://oasis-living.com/contact-us/">
              Contact Us
            </SLinkHref>
            <SLink href="https://oasis-living.com/fees/">Fees</SLink>
            <SLinkHref
              href="https://goo.gl/maps/ihix14UGxjyqgZXg8"
              target="_blank"
            >
              Location
            </SLinkHref>
          </SCol>
          <SCol xs="12" sm="4" md="4" lg="2">
            <BoldTitle>Service</BoldTitle>
            <SLinkHref href="https://oasis-living.com/landlord/">
              Landlords
            </SLinkHref>
            <SLinkHref href="https://oasis-living.com/tenant/">
              Tenants
            </SLinkHref>
            <SLinkHref href="https://oasis-living.com/pages/">
              Tenancy Guides
            </SLinkHref>
          </SCol>
          <SCol xs="12" sm="4" md="4" lg="2">
            <BoldTitle>Legal</BoldTitle>
            <SLinkHref href="https://oasis-living.com/terms-and-conditions/">
              Terms & Conditions
            </SLinkHref>
            <SLinkHref href="https://oasis-living.com/privacy-policy/">
              Privacy Policy
            </SLinkHref>
          </SCol>
          <SCol xs="12">
            <AffiliationsSection $isWhite={true} />
          </SCol>
        </SRow>
        {/* <SRow> */}
        {/* <AffiliationsSection isWhite={true} /> */}
        {/* </SRow> */}
      </SContainer>
    </SFooter>
  );
};

Footer.propTypes = {};

export default Footer;
