import styled from 'styled-components';
import { Link } from 'gatsby';
import { Navbar } from 'react-bootstrap';
import Button from 'components/Button';
import { mediaBreakpointDown } from 'utils/styledCssHelpers';
import { calcResize } from 'components/Layout/styles';

export const StyledHeader = styled.header`
  background-color: ${props =>
    props.mode === 'light'
      ? 'transparent'
      : props.$bgColor || props.theme.colors.main};
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;

  & .navbar-toggler {
    z-index: 120;
    background-color: #ffffff;
    border-color: ${props =>
      props.mode === 'light' ? props.theme.light_accent : 'transparent'};
  }

  & .navbar-collapse {
    z-index: 90;

    ${props =>
      mediaBreakpointDown(
        'md',
        `
          background-color: ${props.theme.colors.main}
        `,
      )}

    ${mediaBreakpointDown(
      'md',
      `
      position: absolute;
      width: 100vw;
      left: 0;
      padding: 15px;
      padding-top: 60px;
      top: 0px;
  `,
    )}
  }
`;

const getNavLinkColor = props => {
  return props.mode === 'dark'
    ? props.type === 'light'
      ? '#ffffff'
      : props.theme.colors.main
    : '#ffffff';
};

export const StyledBrandName = styled(Link)`
  z-index: 120;
  padding: 0;
`;

export const StyledNavbar = styled(Navbar)`
  background-color: ${props =>
    props.mode === 'light'
      ? 'transparent'
      : props.$bgColor || props.theme.colors.main};
  padding-top: 10px;
  padding-bottom: 10px;

  max-width: ${calcResize(1200, 1940)};
  .dropdown {
    &:hover .dropdown-menu {
      display: block;
      margin-top: 0;
    }
    &.dropdown-item {
      color: #192f54 !important;
    }
    a {
      &:hover {
        color: ${props => props.theme.colors.accent} !important;
      }
    }
  }
`;

export const StyledNavLink = styled(Link)`
  font-size: ${calcResize(13, 14)};
  display: block;
  font-weight: 500;
  padding: 0.5rem;
  color: ${props => getNavLinkColor(props)} !important;

  text-transform: uppercase;

  ${mediaBreakpointDown(
    'xl',
    `
    padding: 5px;
  `,
  )}
  ${mediaBreakpointDown(
    'lg',
    `
    padding: 5px 0;
  `,
  )}
  ${mediaBreakpointDown(
    'md',
    `
    color: #ffffff !important;
  `,
  )}

  & .phone-icon {
    margin-right: 5px;
    height: 15px;
    width: 15px;
    fill: ${props => getNavLinkColor(props)};
    ${mediaBreakpointDown(
      'md',
      `
      fill: #ffffff !important;
    `,
    )}
  }
  & a {
    color: ${props => getNavLinkColor(props)};
    &:hover {
      color: ${props => props.theme.colors.accent};
    }
    ${mediaBreakpointDown(
      'md',
      `
      color: #ffffff !important;
    `,
    )}
  }
`;

export const StyledNavLinkWithExternalUrl = styled.a`
  font-size: ${calcResize(13, 14)};
  display: block;
  font-weight: 500;
  padding: 0.5rem;
  color: ${props => getNavLinkColor(props)};

  text-transform: uppercase;

  color: ${props => getNavLinkColor(props)};

  &:hover {
    color: ${props => props.theme.colors.accent};
  }

  ${mediaBreakpointDown(
    'xl',
    `
    padding: 5px;
  `,
  )}
  ${mediaBreakpointDown(
    'lg',
    `
    padding: 5px 0;
  `,
  )}
  ${mediaBreakpointDown(
    'md',
    `
    color: #ffffff !important;
  `,
  )}
`;

export const StyledPhoneNavLink = styled.a`
  font-size: ${calcResize(13, 14)};
  display: block;
  font-weight: 500;
  padding: 0.5rem;
  color: ${props => getNavLinkColor(props)};

  text-transform: uppercase;

  ${mediaBreakpointDown(
    'xl',
    `
    padding: 5px;
    font-size: 11px;
  `,
  )}
  ${mediaBreakpointDown(
    'lg',
    `
    padding: 5px 0;
  `,
  )}
  ${mediaBreakpointDown(
    'md',
    `
    color: #ffffff !important;
  `,
  )}

  & .phone-icon {
    margin-right: 5px;
    height: 15px;
    width: 15px;
    fill: ${props => getNavLinkColor(props)};
    ${mediaBreakpointDown(
      'md',
      `
      fill: #ffffff !important;
    `,
    )}
  }
`;

export const SLoginButton = styled(Button)`
  background-color: ${props =>
    props.mode === 'light' ? '#ffffff' : props.theme.colors.accent};
  max-width: 110px;
  font-size: 13px;
  padding: 0.5rem;
  border-color: transparent;
  color: ${props =>
    props.mode === 'dark'
      ? props.theme.colors.primary
      : props.theme.colors.main};
  text-transform: uppercase;
  margin-right: 0.5rem;
  border-radius: 3px;
  font-weight: bold;

  & .user-icon {
    margin-right: 5px;
    fill: ${props =>
      props.mode === 'dark'
        ? props.theme.colors.primary
        : props.theme.colors.main};
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
    color: #ffffff !important;
    background-color: ${props => props.theme.colors.main} !important;
    border: 1px solid #ffffff;
    & .user-icon {
      fill: #ffffff;
    }
  }

  ${mediaBreakpointDown(
    'md',
    `
      max-width: 100px;
      min-height: 40px;
      padding: 0.3rem;
      display:block;
      margin-bottom: 5px;
  `,
  )}
`;

export const StyledButtonContentType = styled.button`
  background-color: ${
    props =>
      props.active
        ? props.mode === 'dark'
          ? '#ffffff' // Color for dark mode but active
          : props.theme.colors.main // Color for light mode but active
        : props.mode === 'light'
        ? '#ffffff' // Color for light mode but inactive
        : props.theme.colors.main // Color for dark mode but inactive
  };
  max-width: 90px;
  min-height: 45px;
  font-size: 13px;
  padding: 10px 15px;
  color: ${
    props =>
      props.active
        ? props.mode === 'dark'
          ? props.theme.colors.main // Color for dark mode but active
          : '#ffffff' // Color for light mode but active
        : props.mode === 'light'
        ? props.theme.colors.main // Color for light mode but inactive
        : props.theme.colors.light_accent // Color for dark mode but inactive
  };
  border-color: ${
    props =>
      props.mode === 'dark'
        ? props.theme.colors.main // Color for dark mode but active
        : '#ffffff' // Color for light mode but active
  };

  border-style: solid;
  border-width: 1px;
  border-radius: ${props =>
    props.type === 'landlord' ? '10px 0 0 10px' : '0 10px 10px 0'};

  &:hover {
    border-style: solid;
    border-width: 1px;
    border-radius: ${props =>
      props.type === 'landlord' ? '10px 0 0 10px' : '0 10px 10px 0'};
  }

  /* ${mediaBreakpointDown(
    'xl',
    `
  max-width: 70px;
  min-height: 40px;

  `,
  )} */

  &:active,
  &:hover {
    outline: none;
    box-shadow: none;
    color: ${
      props =>
        props.mode === 'dark'
          ? props.theme.colors.main // Color for dark mode on hover and active/hover
          : '#ffffff' // Color for light mode on hover and active/hover
    } !important;
    background-color: ${
      props =>
        props.mode === 'dark'
          ? '#ffffff' // BGColor for dark mode on hover and active/hover
          : props.theme.colors.main // BGColor for light mode on hover and active/hover
    } !important;
  }
`;

export const StyledNavBarActions = styled.span`
  ${mediaBreakpointDown(
    'md',
    `
      margin-right: 1rem;
    `,
  )}
`;
