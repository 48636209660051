import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import HeroButton from '../HeroButton';
import {
  mediaBreakpointDown,
  mediaBreakpointUp,
} from '../../utils/styledCssHelpers';
import { calcResize } from 'components/Layout/styles';

const getMainColor = (theme, color) => {
  return color
    ? (color === 'rightmove' && theme.colors.rightmove) ||
        (color === 'zoopla' && theme.colors.zoopla) ||
        (color === 'accent' && theme.colors.accent)
    : theme.colors.main;
};

const getHoverColor = (theme, color) => {
  return color
    ? (color === 'rightmove' && theme.colors.rightmove) ||
        (color === 'zoopla' && theme.colors.zoopla) ||
        (color === 'accent' && theme.colors.accent_dark)
    : theme.colors.primary;
};

//prettier-ignore
export const SFullButton = styled.button`
  min-width: ${props => props.width ? props.width : '180px'};
  min-height: ${props => props.height ? props.height : '45px'};
  border-radius: 3px;
  color: ${props => props.$fontColor || '#ffffff'};
  display: flex;
  padding: 15px ${calcResize(30, 45)};
  justify-content: space-evenly;
  align-items: center;
  font-weight: bold;
  background-color: ${props => props.disabled ? '#de97a5' : getMainColor(props.theme, props.$backgroundColor)};
  box-shadow: ${props => props.theme.shadow};
  border-width: 0;
  border-color: ${props => props.disabled ? '#de97a5' : getMainColor(props.theme, props.$backgroundColor)};
  font-size: ${props => props.fontSize ? props.fontSize : props.theme.fontSizes.button};
  margin: ${props => props.margin || 'unset'};
  ${props => props.$extraStyle};
  
  &:hover {
    color: #ffffff;
    background: ${props =>  props.disabled ? '#de97a5' : getHoverColor(props.theme, props.$backgroundColor)};
    border-color: ${props =>  props.disabled ? '#de97a5' : getHoverColor(props.theme, props.$backgroundColor)};
  }

  a {
    color: ${props => props.$fontColor || '#ffffff'};

    &:hover {
      text-decoration: none;
    }
  }

  ${mediaBreakpointDown(
    'md',
    `
    margin: 0 auto;
  `,
  )};

`;

export const SOutlineButton = styled(Button)`
  min-width: ${props => (props.width ? props.width : '180px')};
  min-height: ${props => (props.height ? props.height : '45px')};
  border-radius: 8px;
  color: ${props => props.theme.colors.main};
  background: #ffffff;
  box-shadow: ${props => props.theme.shadow};
  border: 2px solid ${props => props.theme.colors.main};

  &:hover {
    color: #ffffff;
    background: ${props => props.theme.colors.main};
    border: none;
  }
`;

export const SHeroRMoveBtn = styled(HeroButton)`
  ${mediaBreakpointUp(
    'md',
    `
    margin-right: 15px;
  `,
  )}
  ${mediaBreakpointDown(
    'md',
    `
    margin-bottom: 15px;
  `,
  )}
  background: #00deb6;
  &:hover {
    background: #00deb6 !important;
  }
`;
export const SHeroZooplaMoveBtn = styled(HeroButton)`
  background: #8046f2;
  &:hover {
    background: #8046f2 !important;
  }
`;

// export const ArrowThinRightIcon = styled(ArrowThinRight)``;
