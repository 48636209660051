import { calcResize } from 'components/Layout/styles';
import styled from 'styled-components';
import { mediaBreakpointDown } from 'utils/styledCssHelpers';

export const SHeroBtn = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  min-width: 280px;
  border: 0px;
  outline: 0px;
  border-radius: 5px;
  height: 60px;
  box-shadow: ${props => props.theme.shadow};

  & .button-icon {
    padding: 0 10px;
    margin: 10px 0;
    border-right: 0.1pt solid #ffffff;
  }
  & .icon-image {
    height: 25px;
    width: 25px;
  }
  & .text {
    color: #ffffff;
    font-weight: bold;
    padding: ${calcResize(10, 15)} 10px;
    font-size: 15px;
  }

  ${mediaBreakpointDown(
    'md',
    `
    margin: 0 auto 10px;
  `,
  )};
`;
