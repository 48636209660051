import styled from 'styled-components';
import { mediaBreakpointUp } from 'utils/styledCssHelpers';

export const SContainer = styled.div`
  position: absolute;
  height: ${props => (props.top || props.bottom ? '20%' : '100%')};
  bottom: ${props => (props.bottom ? '0' : 'unset')};
  left: ${props => (props.left ? '0' : props.moveLeft && props.moveLeft)};
  right: ${props => (props.right ? '0' : 'unset')};
  top: ${props => (props.top ? '0' : 'unset')};
  display: ${props => (props.isVisibleMobile ? 'block' : 'none')};
  transform: translateY(${props => props.$moveY || '-30px'});
  z-index: ${props => props.indexZ};
  
  fill: ${props =>
    props.variant
      ? (props.variant === 'main' && props.theme.colors.main) ||
        (props.variant === 'pastel_blue' && props.theme.colors.pastel_blue)
      : props.theme.colors.accent};
  width: 100%;
  
  & svg {
  width: ${props => props.width};
    float: ${props => (props.moveToRight ? 'right' : 'unset')}
  }
  overflow: hidden;
}

${props =>
  mediaBreakpointUp(
    'md',
    `
  
    height: ${props.top || props.bottom ? '21%' : '100%'};
    display: block;
  `,
  )}
${props =>
  mediaBreakpointUp(
    'lg',
    `
    height: ${props.top || props.bottom ? '17%' : '100%'};

  `,
  )}
${props =>
  mediaBreakpointUp(
    'xl',
    `
    height: ${props.top || props.bottom ? '10%' : '100%'};

  `,
  )}
`;
