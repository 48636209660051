import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import PhoneIcon from 'images/svg/phone-icon.svg';
import UserIcon from 'images/svg/user-icon.svg';
import Logo from 'components/Logo';

import {
  StyledHeader,
  StyledBrandName,
  StyledNavLink,
  StyledNavbar,
  StyledButtonContentType,
  SLoginButton,
  StyledNavBarActions,
  StyledPhoneNavLink,
  StyledNavLinkWithExternalUrl,
} from './styles.js';
import { navigate } from 'gatsby';
import { useLocation } from '@gatsbyjs/reach-router';

// Renders the login button and the different content types (landlord, tenant)
const NavBarActions = ({ mode, contentType }) => {
  const [activeContentType, setActiveContentType] = useState('landlord');
  const location = useLocation();
  const handleSetActiveContentType = (type = 'landlord') =>
    setActiveContentType(type);

  useEffect(() => {
    setActiveContentType(contentType);
  }, [contentType]);

  return (
    <StyledNavBarActions>
      <SLoginButton
        mode={mode}
        onClick={
          location.pathname.includes('/users/login')
            ? '#'
            : 'https://oasis-living.com/users/login/'
        }
      >
        <UserIcon className="user-icon" /> Login
      </SLoginButton>
    </StyledNavBarActions>
  );
};

NavBarActions.propTypes = {
  mode: PropTypes.string,
  contentType: PropTypes.string,
};

const Header = ({
  logoSrc,
  $bgColor,
  mode = 'dark',
  linkType = 'light',
  isTenant = false,
  logoType,
}) => {
  logoType = logoType || mode;
  // if logoSrc, buttonColor are not null, it assumes this is an ad campaign
  return (
    <StyledHeader mode={mode} $bgColor={$bgColor}>
      <StyledNavbar
        collapseOnSelect
        className={`container`}
        expand="lg"
        sticky="top"
        mode={mode}
        $bgColor={$bgColor}
      >
        <StyledBrandName
          type={linkType}
          mode={mode}
          aria-label="Oasis Living Logo"
          to="https://oasis-living.com/"
          className={`navbar-brand`}
        >
          <Logo bgColor={logoType === 'dark' ? 'dark' : 'white'} width={150} />
        </StyledBrandName>
        {logoSrc ? null : <StyledNavbar.Toggle aria-controls="navbar-nav" />}
        <StyledNavbar.Collapse id="navbar-nav">
          <Nav className={`me-auto`}>
            <li className="nav-item dropdown">
              <StyledNavLink
                mode={mode}
                type={linkType}
                to="#"
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Our Services
              </StyledNavLink>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <StyledNavLink
                  className="dropdown-item"
                  to="https://oasis-living.com/landlord/tenant-find/"
                  mode={'dark'}
                  type={'dark'}
                >
                  Find Tenant
                </StyledNavLink>
                <StyledNavLink
                  className="dropdown-item"
                  mode={'dark'}
                  type={'dark'}
                  to="https://oasis-living.com/landlord/property-management/"
                >
                  Property Management
                </StyledNavLink>
                <StyledNavLink
                  className="dropdown-item"
                  mode={'dark'}
                  type={'dark'}
                  to="https://oasis-living.com/build-to-rent"
                >
                  Build To Rent
                </StyledNavLink>
              </div>
            </li>
            {/* <StyledNavLink mode={mode} type={linkType} to="https://oasis-living.com/build-to-rent/">
              Build To Rent
            </StyledNavLink> */}
            <StyledNavLinkWithExternalUrl
              mode={mode}
              type={linkType}
              target="_blank"
              href="https://www.rightmove.co.uk/property-to-rent/find/Oasis-Living/London.html?locationIdentifier=BRANCH%5E220118&propertyStatus=all&includeLetAgreed=true&_includeLetAgreed=on"
            >
              Our Properties
            </StyledNavLinkWithExternalUrl>
            <StyledNavLinkWithExternalUrl
              mode={mode}
              type={linkType}
              href="https://oasis-living.com/stays/"
            >
              Mid Lets
            </StyledNavLinkWithExternalUrl>
            <StyledNavLink
              mode={mode}
              type={linkType}
              to="https://oasis-living.com/fees/"
            >
              Fees
            </StyledNavLink>
            <li className="nav-item dropdown">
              <StyledNavLink
                mode={mode}
                type={linkType}
                to="#"
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Content
              </StyledNavLink>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <StyledNavLink
                  className="dropdown-item"
                  mode={'dark'}
                  type={'dark'}
                  to="https://oasis-living.com/blog/"
                >
                  Blog
                </StyledNavLink>
                <StyledNavLink
                  className="dropdown-item"
                  mode={'dark'}
                  type={'dark'}
                  to="https://oasis-living.com/podcast/"
                >
                  Podcast
                </StyledNavLink>
              </div>
            </li>

            <StyledNavLink
              mode={mode}
              type={linkType}
              to="https://oasis-living.com/about-us/"
            >
              About Us
            </StyledNavLink>
          </Nav>
          <Nav className={`ms-auto me-3`}>
            <li className="nav-item dropdown">
              <StyledNavLink
                mode={mode}
                type={linkType}
                to="#"
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Contact
              </StyledNavLink>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <StyledPhoneNavLink
                  className="phone-nav dropdown-item"
                  href="tel:+442039369698"
                  mode={'dark'}
                  type={'dark'}
                >
                  <PhoneIcon className="phone-icon" /> +44 20 3936 9698
                </StyledPhoneNavLink>
                <StyledNavLink
                  className="dropdown-item"
                  mode={'dark'}
                  type={'dark'}
                  to="https://oasis-living.com/contact-us/"
                >
                  Contact Us
                </StyledNavLink>
              </div>
            </li>
          </Nav>
          <Nav>
            <NavBarActions
              mode={mode}
              contentType={isTenant ? 'tenant' : 'landlord'}
            />
          </Nav>
        </StyledNavbar.Collapse>
      </StyledNavbar>
    </StyledHeader>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  logoSrc: PropTypes.string,
  buttonColor: PropTypes.string,
  $bgColor: PropTypes.string,
  mode: PropTypes.string,
  linkType: PropTypes.string,
  logoType: PropTypes.string,
  isTenant: PropTypes.bool,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
