import styled, { css } from 'styled-components';

// Example of use. Delete if not needed.
export const SBackground = styled.div`
  width: ${props => (props.withBackground ? props.iconWidth + 'px' : null)};
  height: ${props => (props.withBackground ? props.iconHeight + 'px' : null)};
  border-radius: 8px;
  background: ${props =>
    props.withBackground ? props.theme.colors.primary_transparent : 'none'};
  /* margin-right: 10px; */

  svg {
    margin: 0 auto;
    width: ${props =>
      props.withBackground
        ? props.iconWidth / 1.5 + 'px'
        : props.iconWidth + 'px'};
    height: ${props =>
      props.withBackground
        ? props.iconHeight / 1.5 + 'px'
        : props.iconHeight + 'px'};

    color: ${props => (props.color ? props.color : props.theme.colors.primary)};

    ${props =>
      // only if fill or shouldFill is defined or true respectively, we set fill: in css else dont set. This helps finer control as some react-icons if filled looks bad, but we need fill for footer icons still
      props.shouldFill || props.fill
        ? css`
            fill: ${props.fill
              ? props.fill
              : props.theme.colors.primary} !important;
            path {
              ${
                '' /* this is needed because in some svg the internal paths are also hardcoded with black fill, to override , e.g. Quarterly inspection svg from flaticons */
              }
              fill: ${props.fill
                ? props.fill
                : props.theme.colors.primary} !important;
            }
          `
        : null};

    path {
      stroke: ${props =>
        props.fill ? props.fill : props.theme.colors.primary};
    }
  }
`;
