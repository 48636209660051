/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from '../Header';
import './layout.css';
import { DefaultTheme, GlobalStyle, SToastContainer } from './styles';
import Footer from '../Footer';
import LoaderSpinner from 'components/LoaderSpinner';
import { useStateMachine } from 'little-state-machine';

const Layout = ({
  children,
  navType = 'dark',
  logoType = 'dark',
  navBgColor,
  navLinkColor,
  navLinkType = 'light',
  isTenant = false,
  withHeader = true,
  withFooter = true,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { state } = useStateMachine();

  return (
    <>
      <GlobalStyle theme={DefaultTheme} />
      <LoaderSpinner isLoading={state.isLoading} />
      {withHeader && (
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          mode={navType}
          $bgColor={navBgColor}
          linkColor={navLinkColor}
          linkType={navLinkType}
          logoType={logoType}
          isTenant={isTenant}
        />
      )}
      <SToastContainer
        position="top-end"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {children}
      {withFooter && <Footer />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  logoSrc: PropTypes.string,
  isTenant: PropTypes.bool,
  buttonColor: PropTypes.string,
  navType: PropTypes.string,
  logoType: PropTypes.string,
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
  navBgColor: PropTypes.string,
  navLinkColor: PropTypes.string,
  navLinkType: PropTypes.string,
};

export default Layout;
