/**
 *
 * Icon
 *
 */

import React from 'react';
import { SBackground } from './styles';
import StarIcon from 'images/svg/star.svg';
import SuccessIcon from 'images/svg/success.svg';
import FacebookIcon from 'images/svg/facebook.svg';
import InstagramIcon from 'images/svg/instagram.svg';
import WhatsappIcon from 'images/svg/whatsapp.svg';
import YoutubeIcon from 'images/svg/youtube.svg';
import LinkedinIcon from 'images/svg/linkedin.svg';
import TwitterIcon from 'images/svg/brand-twitter.svg';

import PropTypes from 'prop-types';
import loadable from '@loadable/component';

/* React Icons imports */
import { AiOutlineCamera } from '@react-icons/all-files/ai/AiOutlineCamera';
import { GrOverview } from '@react-icons/all-files/gr/GrOverview';
import { AiOutlineSearch } from '@react-icons/all-files/ai/AiOutlineSearch';
import { GrOptimize } from '@react-icons/all-files/gr/GrOptimize';
import { GrChat } from '@react-icons/all-files/gr/GrChat';
import { FiUsers } from '@react-icons/all-files/fi/FiUsers';
import { FaRegMap } from '@react-icons/all-files/fa/FaRegMap';
import { GrDocumentText } from '@react-icons/all-files/gr/GrDocumentText';
import { RiMoneyPoundBoxLine } from '@react-icons/all-files/ri/RiMoneyPoundBoxLine';
import { FiUserCheck } from '@react-icons/all-files/fi/FiUserCheck';
import { BiLike } from '@react-icons/all-files/bi/BiLike';
import { FaRegListAlt } from '@react-icons/all-files/fa/FaRegListAlt';
import { BiExit } from '@react-icons/all-files/bi/BiExit';
import { HiOutlineRefresh } from '@react-icons/all-files/hi/HiOutlineRefresh';
import { FcInspection } from '@react-icons/all-files/fc/FcInspection';
import { GrVmMaintenance } from '@react-icons/all-files/gr/GrVmMaintenance';
import { GrCompliance } from '@react-icons/all-files/gr/GrCompliance';
import { AiOutlineSafety } from '@react-icons/all-files/ai/AiOutlineSafety';
import { BiSupport } from '@react-icons/all-files/bi/BiSupport';
import { RiDashboardLine } from '@react-icons/all-files/ri/RiDashboardLine';
import { AiOutlineTool } from '@react-icons/all-files/ai/AiOutlineTool';

const reactIcons = {
  AiOutlineCamera: <AiOutlineCamera />,
  GrOverview: <GrOverview />,
  AiOutlineSearch: <AiOutlineSearch />,
  GrOptimize: <GrOptimize />,
  GrChat: <GrChat />,
  FiUsers: <FiUsers />,
  FaRegMap: <FaRegMap />,
  GrDocumentText: <GrDocumentText />,
  RiMoneyPoundBoxLine: <RiMoneyPoundBoxLine />,
  FiUserCheck: <FiUserCheck />,
  BiLike: <BiLike />,
  FaRegListAlt: <FaRegListAlt />,
  BiExit: <BiExit />,
  HiOutlineRefresh: <HiOutlineRefresh />,
  FcInspection: <FcInspection />,
  GrVmMaintenance: <GrVmMaintenance />,
  GrCompliance: <GrCompliance />,
  AiOutlineSafety: <AiOutlineSafety />,
  BiSupport: <BiSupport />,
  RiDashboardLine: <RiDashboardLine />,
  AiOutlineTool: <AiOutlineTool />,
};

// import * as reactIcons from '@react-icons/all-files/all';
import { isBrowser } from 'utils/auth';

const getIcon = variant => {
  let icon;
  let iconName;

  if (variant.startsWith('ri:')) {
    iconName = variant.split(':')[1];
    icon = reactIcons[iconName];
  } else if (variant.startsWith('local:')) {
    iconName = variant.split(':')[1];
    const LocalIcon = loadable(() => import(`images/svg/${iconName}.svg`));
    icon = isBrowser && <LocalIcon />;
  } else {
    switch (variant) {
      case 'star':
        icon = <StarIcon />;
        break;
      case 'success':
        icon = <SuccessIcon />;
        break;
      case 'whatsapp':
        icon = <WhatsappIcon />;
        break;
      case 'twitter':
        icon = <TwitterIcon />;
        break;
      case 'facebook':
        icon = <FacebookIcon />;
        break;
      case 'instagram':
        icon = <InstagramIcon />;
        break;
      case 'linkedin':
        icon = <LinkedinIcon />;
        break;
      case 'youtube':
        icon = <YoutubeIcon />;
        break;
      default:
        break;
    }
  }

  return icon;
};

const DynamicReactIcon = ({
  width,
  height,
  iconVariant,
  withBackground,
  fill,
  isIconFill,
}) => {
  return (
    <SBackground
      iconWidth={width}
      iconHeight={height}
      withBackground={withBackground}
      fill={fill}
      color={fill}
      shouldFill={isIconFill}
      className="header-icon" // for signalling to other parts of code for css in container
    >
      {getIcon(iconVariant)}
    </SBackground>
  );
};

DynamicReactIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  iconVariant: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fill: PropTypes.string,
  withBackground: PropTypes.bool,
  isIconFill: PropTypes.bool,
};

export default DynamicReactIcon;
