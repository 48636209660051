import React from 'react';
import { SContainer } from './styles';
import DotSvgLine from 'images/svg/dot_svg_full.svg';
import DotSvgSquare from 'images/svg/dot_svg_small.svg';
import PropTypes from 'prop-types';

/**
 * 
 * Show The DOT SVG on a parent component
 * 
 * This component uses position: absolute to properly place the icons.
 * This component is not visible by default on mobiles, to change use prop isVisibleMobile={true}.
 * In order to display the line version of SVG pattern use isSVGLine={true}
 * Default moveY is -30px, if you want svg to stay at the top or bottom without any transformation set moveY="0"
 * 
 * Use like below:
 * 
 *


      <DotSVG left isVisibleMobile={true} isSVGLine={true} /> -> Default colour accent
      <DotSVG top variant="main" />
      <DotSVG bottom variant="pastel_blue" />
 * 
 */

const DotSVG = props => {
  return (
    // Send all the props to the container
    <SContainer {...props}>
      {props.isSVGLine ? <DotSvgLine /> : <DotSvgSquare />}
    </SContainer>
  );
};

DotSVG.propTypes = {
  left: PropTypes.bool,
  moveLeft: PropTypes.string,
  top: PropTypes.bool,
  right: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  bottom: PropTypes.bool,
  variant: PropTypes.string,
  indexZ: PropTypes.number,
  $moveY: PropTypes.string,
  isSVGLine: PropTypes.bool,
};

export default DotSVG;
