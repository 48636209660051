import React from 'react';
import { SHeroBtn } from './styles';
import PropTypes from 'prop-types';
import Image from '../Image';

function HeroButton({ children, rightImageFilename, ...props }) {
  return (
    // Spred the rest of the props to pass to the parent
    <SHeroBtn {...props}>
      <span className="button-icon">
        <Image filename={rightImageFilename} className="icon-image" />
      </span>
      <span className="text">{children}</span>
    </SHeroBtn>
  );
}

HeroButton.propTypes = {
  rightImageFilename: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default HeroButton;
