import Loader from 'react-loader-spinner';

const { default: styled } = require('styled-components');

export const SDivOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: ${props => (props.isLoading ? `rgba(0, 0, 0, 0.3)` : null)};
  /* background-color: #000; */
  /* opacity: ${props => (props.isLoading ? 0.3 : 0)}; */
`;

export const SLoader = styled(Loader)`
  position: fixed;
  top: 50%;
  left: 50%;
  opacity: 1;
`;
