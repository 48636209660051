import { Link } from 'gatsby';
import { Col, Row, Button, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { calcResize } from '../Layout/styles';
import { mediaBreakpointDown, mediaBreakpointUp } from 'utils/styledCssHelpers';

export const SFooter = styled.div`
  position: relative;
  background: ${props => props.theme.colors.main};
  padding-top: ${calcResize(35, 100)};
  background-repeat: no-repeat;
  overflow: hidden;
  min-height: 615px;
  width: 100%;
`;

export const SRow = styled(Row)`
  display: flex;
  //justify-content: center;
  // padding-top: ${calcResize(30, 100)};
`;

export const SEmailUsButton = styled.a`
  justify-content: center;
  align-items: center;
  display: flex !important;
  font-weight: 600;
  background-color: ${props => props.theme.colors.accent};
  border-radius: 3px;
  width: 200px;
  height: 53px;
  color: ${props => props.theme.colors.main};

  svg {
    fill: ${props => props.theme.colors.main};
    margin: 0 0 2px 10px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.accent_dark};
    color: ${props => props.theme.colors.main};
  }

  ${mediaBreakpointDown(
    'md',
    `
    margin-bottom: 30px;
  `,
  )};
  ${mediaBreakpointDown(
    'lg',
    `
    margin: 0 auto;
  `,
  )};
`;

export const SBrandName = styled.div`
  max-width: 150px;
  width: 150px;
  margin: auto;
`;

export const SFooterMessage = styled.div`
  color: #fff;
  font-size: 17px;

  ${mediaBreakpointUp(
    'xxl',
    `
    padding-right: 490px;
  `,
  )};

  ${mediaBreakpointUp(
    'xl',
    `
    padding-right: 90px;
  `,
  )};
`;

export const SContainer = styled(Container)`
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
`;

export const SFooterMessageLink = styled.a`
  display: block;
  font-size: 17px !important;
  line-height: 40px;
  color: #8fc0ff !important;

  &:hover {
    color: #7db6ff;
  }
`;

export const SOasisLiving = styled.div`
  font-weight: 600;
  color: #ffffff;
  font-size: 35px;
  margin-bottom: 27px;

  ${mediaBreakpointDown(
    'md',
    `
    text-align: center;
  `,
  )}
`;

export const STelephoneNumber = styled.a`
  color: #ffffff;
  font-size: 16px;

  ${mediaBreakpointDown(
    'md',
    `
    text-align: center;
    // padding-top: 30px;
  `,
  )};

  ${mediaBreakpointDown(
    'sm',
    `
    text-align: center;
    padding-top: 30px;
  `,
  )};
`;

export const SSocialMediaLinks = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 200px;
  padding-top: 30px;
  vertical-align: middle;

  ${mediaBreakpointDown(
    'lg',
    `
    padding-top: 0px;
    
  `,
  )};

  ${mediaBreakpointDown(
    'md',
    `
    padding-top: 30px;
    display: inline-flex;
  `,
  )};
`;

export const BoldTitle = styled.div`
  font-size: 21px;
  color: #7eace6;
  margin-bottom: 26px;
  padding-top: 15px;
`;

export const SLink = styled(Link)`
  font-size: 16px;
  color: #fff;
`;

export const SLinkHref = styled.a`
  font-size: 16px;
  color: #fff;
`;

export const SHref = styled.a`
  display: block;
  font-size: 16px;
  line-height: 40px;
  color: #000;

  &:hover {
    color: #000;
  }
`;

export const SCol = styled(Col)`
  a {
    display: block;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  ${mediaBreakpointDown(
    'lg',
    `
    text-align: center;
    margin-bottom: 30px;
    justify-content: center;
  `,
  )};
`;
