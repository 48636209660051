/**
 *
 * AffiliationsSection
 *
 */

import React from 'react';
import { SContainer, SSection, SImage } from './styles';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

const icons = isWhite => [
  {
    url: isWhite ? 'affiliations/prs_white.png' : 'affiliations/prs_logo.png',
    alt: 'Affiliation Partner - Property Redress Scheme',
  },
  {
    url: isWhite
      ? 'affiliations/money_shield_white.png'
      : 'affiliations/money_shield.png',
    alt: 'Affiliation Partner - Money Shield Scheme',
  },
  {
    url: isWhite ? 'affiliations/tds_white.png' : 'affiliations/tds_logo.png',
    alt: 'Affiliation Partner - Tenancy Deposit Scheme',
  },
  {
    url: isWhite
      ? 'affiliations/barclays_white.png'
      : 'affiliations/barclays_logo.png',
    alt: 'Affiliation Partner - Barclays',
  },
  {
    url: isWhite
      ? 'affiliations/truelayer_white.png'
      : 'affiliations/truelayer_logo.png',
    alt: 'Affiliation Partner - Truelayer',
  },
  {
    url: isWhite
      ? 'affiliations/rightmove_white.png'
      : 'affiliations/rightmove_logo.png',
    alt: 'Affiliation Partner - Rightmove',
  },
  {
    url: isWhite
      ? 'affiliations/zoopla_white.png'
      : 'affiliations/zoopla_logo.png',
    alt: 'Affiliation Partner - Zoopla',
  },
  // { url: 'affiliations/client-money-protect.png', alt: 'Affiliation Partner - Client Money Protect' },
];

const AffiliationsSection = ({ $isWhite = false, $backgroundColor }) => {
  return (
    <SSection
      name="Affiliations"
      $isWhite={$isWhite}
      $backgroundColor={$backgroundColor}
    >
      <SContainer fluid $isWhite={$isWhite}>
        <Row className={'align-items-center'}>
          {icons($isWhite).map((image, i) => (
            <Col
              // xl={3}
              // lg={3}
              // xs={4}
              key={i}
              className="px-3 px-md-5 py-3 px-lg-4 d-flex justify-content-center"
            >
              <SImage filename={image.url} alt={image.alt} isWhite={false} />
            </Col>
          ))}
        </Row>
      </SContainer>
    </SSection>
  );
};

AffiliationsSection.propTypes = {
  $isWhite: PropTypes.bool,
  $backgroundColor: PropTypes.string,
};

export default AffiliationsSection;
