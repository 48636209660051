import React from 'react';
import DarkBgLogo from 'images/svg/logo_blue.svg';
import WhiteBgLogo from 'images/svg/logo_white.svg';
import PropTypes from 'prop-types';

const Logo = ({ width, bgColor }) => {
  const logo =
    bgColor === 'dark' ? (
      <DarkBgLogo alt="Oasis Living Logo" width={width} />
    ) : (
      <WhiteBgLogo alt="Oasis Living Logo" width={width} />
    );

  return logo;
};

Logo.propTypes = {
  width: PropTypes.number,
  bgColor: PropTypes.string,
};
Logo.defaultProps = {
  bgColor: `dark`,
  width: 150,
};

export default Logo;
