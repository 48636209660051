/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { SDivOverlay, SLoader } from './styles';

const LoaderSpinner = ({ isLoading }) => {
  if (isLoading) {
    return (
      <SDivOverlay isLoading={isLoading}>
        <SLoader
          visible={isLoading}
          width={80}
          color="#1360EF"
          height={80}
          type="Grid"
        />
      </SDivOverlay>
    );
  } else {
    return null;
  }
};

LoaderSpinner.propTypes = {};

export default LoaderSpinner;
