import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { calcResize } from 'components/Layout/styles';
import { mediaBreakpointDown } from 'utils/styledCssHelpers';
import Image from 'components/Image';

/* 
calc( 48px + (68 - 48) * (100vw - 320px) / (1024 - 400) );

48 - Min size
68 - Max size

320 - min screen size
1024 - max screen size

*/

export const SSection = styled.section`
  background-color: ${props =>
    props.$isWhite ? null : props.$backgroundColor || '#f5f7fb'};
`;

export const SContainer = styled(Container)`
  padding-inline: ${props => (props.$isWhite ? null : calcResize(20, 350))};
  padding-top: 10px;
  /* margin-top: ${calcResize(20, 100)}; */
`;

export const SImage = styled(Image)`
  max-width: ${props => (props.$isWhite ? '125px' : '200px')};
  min-width: 75px;
`;
